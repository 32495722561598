import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Dashboard from './Dashboard';
import ViewFiles from './ViewFiles';

const App: React.FC = () => {
  return (
    <Router>
      <Box>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/view-files" element={<ViewFiles />} />
        </Routes>
      </Box>
    </Router>
  );
};

export default App;
