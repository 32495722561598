import { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  PaginationState,
} from '@tanstack/react-table';
import {
  Box,
  Button,
  Typography,
  TextField,
  Pagination,
  MenuItem,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download'; // Import DownloadIcon

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios'; // Import axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const apiUrl = process.env.REACT_APP_API_URL;

const App = () => {
  type Dashboard = {
    id: number;
    batchNumber: string;
    standardReview: number;
    standardDelivered: number;
    standardTotal: number;
    deliveryDateStandard: string;
    batchIncomingDate: string;
    deliveryDateNonStandard: string;
    nonStandardReview: number;
    nonStandardDelivered: number;
    nonStandardTotal: number;
    pipelineErrors: number;
    totalUniqueVatNumbers: number;
    notProcessedIndividualNumbers: number;
    totalIndividualNumbers: number;
    batchOutput?: any;
  };

  const [total, setTotal] = useState<number>(0);
  const [filterValue, setFilterValue] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false); // Add loading state
  const [downloadingBatch, setDownloadingBatch] = useState<string | null>(null);

  const navigate = useNavigate();

  const applyFilter = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page
    dataQuery.refetch(); // Explicitly refetch the data
  };

  const fetchData = async () => {
    setIsLoading(true); // Set loading to true before fetching
    try {
      const { pageIndex, pageSize } = pagination;

      const params: { page: number; limit: number; filter?: string } = {
        page: pageIndex + 1,
        limit: pageSize,
      };

      if (filterValue !== '') {
        params.filter = filterValue;
      }

      const response = await axios.get(`${apiUrl}/dashboard`, {
        params,
      });
      const { data, count } = response.data;
      setTotal(count);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    } finally {
      setIsLoading(false); // Set loading to false after fetching
    }
  };

  const dataQuery = useQuery({
    queryKey: ['data', pagination],
    queryFn: () => fetchData(),
    placeholderData: keepPreviousData,
  });

  const data = dataQuery.data || []; // Use fetched data or an empty array

  const columnHelper = createColumnHelper<Dashboard>();

  // Function to handle cell click
  const handleCellClick = (
    batchNumber: string,
    type: string,
    sub_type: string = ''
  ) => {
    navigate(
      `/view-files?batchNumber=${batchNumber}&type=${type}${sub_type ? `&sub_type=${sub_type}` : ''}`
    );
  };

  const handleDownload = async (batchNumber: string) => {
    if (downloadingBatch) return; // Prevent multiple simultaneous downloads
    
    setDownloadingBatch(batchNumber);
    let url: string | null = null;
    
    try {
      const response = await axios.get(
        `${apiUrl}/dashboard/batch-download-files/${batchNumber}`,
        {
          responseType: 'blob',
          timeout: 1200000, // 30 second timeout
        }
      );

      // Validate the response
      if (!(response.data instanceof Blob)) {
        throw new Error('Invalid response format');
      }

      url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `batch-${batchNumber}.zip`; // More descriptive filename
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Download failed:', error);
      // You might want to show this error in your UI
      alert('Failed to download the batch files. Please try again.');
    } finally {
      if (url) {
        window.URL.revokeObjectURL(url);
      }
      setDownloadingBatch(null);
    }
  };

  const columns = [
    columnHelper.accessor('batchNumber', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            textDecoration: 'underline',
            fontWeight: 600,
          }}
          onClick={() => handleCellClick(info.getValue(), 'batch')} // Pass batch number and column type
        >
          {info.getValue()}
        </Button>
      ),
      header: () => <span style={{ fontWeight: 600 }}>Batch Number</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.group({
      id: 'standard',
      header: () => <span style={{ fontWeight: 600 }}>Standard</span>,
      columns: [
        columnHelper.accessor('standardDelivered', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                textDecoration: 'underline',
                fontWeight: 600,
              }}
              onClick={() =>
                handleCellClick(
                  info.row.original.batchNumber,
                  'standard',
                  'delivered'
                )
              } // Pass batch number and column type
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Delivered</span>,
          footer: (info) => info.column.id,
        }),
        columnHelper.accessor('standardReview', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                textDecoration: 'underline',
                fontWeight: 600,
              }}
              onClick={() =>
                handleCellClick(
                  info.row.original.batchNumber,
                  'standard',
                  'review'
                )
              }
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Review</span>,
          footer: (info) => info.column.id,
        }),
        columnHelper.accessor('standardTotal', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                textDecoration: 'underline',
                fontWeight: 600,
              }}
              onClick={() =>
                handleCellClick(
                  info.row.original.batchNumber,
                  'standard',
                  'total'
                )
              }
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Total</span>,
          footer: (info) => info.column.id,
        }),
        columnHelper.accessor('deliveryDateStandard', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                // textDecoration: 'underline',
                fontWeight: 600,
              }}
              // onClick={() =>
              //   handleCellClick(
              //     info.row.original.batchNumber,
              //     'standard',
              //     'deliveryDate'
              //   )
              // }
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Delivery Date</span>,
          footer: (info) => info.column.id,
        }),
      ],
    }),
    columnHelper.group({
      id: 'nonStandard',
      header: () => <span style={{ fontWeight: 600 }}>Non Standard</span>,
      columns: [
        columnHelper.accessor('nonStandardDelivered', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                // textDecoration: 'underline',
                fontWeight: 600,
              }}
              // onClick={() =>
              //   handleCellClick(
              //     info.row.original.batchNumber,
              //     'non_standard',
              //     'delivered'
              //   )
              // }
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Delivered</span>,
          footer: (info) => info.column.id,
        }),
        columnHelper.accessor('nonStandardReview', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                // textDecoration: 'underline',
                fontWeight: 600,
              }}
              // onClick={() =>
              //   handleCellClick(
              //     info.row.original.batchNumber,
              //     'non_standard',
              //     'review'
              //   )
              // }
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Review</span>,
          footer: (info) => info.column.id,
        }),
        columnHelper.accessor('nonStandardTotal', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                // textDecoration: 'underline',
                fontWeight: 600,
              }}
              // onClick={() =>
              //   handleCellClick(
              //     info.row.original.batchNumber,
              //     'non_standard',
              //     'total'
              //   )
              // }
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Total</span>,
          footer: (info) => info.column.id,
        }),
        columnHelper.accessor('deliveryDateNonStandard', {
          cell: (info) => (
            <Button
              variant="text"
              sx={{
                color: '#3f51b5',
                // textDecoration: 'underline',
                fontWeight: 600,
              }}
              // onClick={() =>
              //   handleCellClick(
              //     info.row.original.batchNumber,
              //     'non_standard',
              //     'deliveryDate'
              //   )
              // }
            >
              {info.getValue()}
            </Button>
          ),
          header: () => <span style={{ fontWeight: 600 }}>Delivery Date</span>,
          footer: (info) => info.column.id,
        }),
      ],
    }),
    columnHelper.accessor('pipelineErrors', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            textDecoration: 'underline',
            fontWeight: 600,
          }}
          onClick={() =>
            handleCellClick(info.row.original.batchNumber, 'pipeline_errors')
          }
        >
          {info.getValue()}
        </Button>
      ),
      header: () => <span style={{ fontWeight: 600 }}>Pipeline Errors</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('totalUniqueVatNumbers', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            textDecoration: 'underline',
            fontWeight: 600,
          }}
          onClick={() => handleCellClick(info.row.original.batchNumber, 'total_unique_vat')}
        >
          {info.getValue()}
        </Button>
      ),
      header: () => (
        <span style={{ fontWeight: 600 }}>Total Unique VAT Numbers</span>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('notProcessedIndividualNumbers', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            // textDecoration: 'underline',
            fontWeight: 600,
          }}
          // onClick={() =>
          //   handleCellClick(info.row.original.batchNumber, 'not_processed')
          // }
        >
          {info.getValue()}
        </Button>
      ),
      header: () => (
        <span style={{ fontWeight: 600 }}>
          Not Processed Individual Numbers
        </span>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('batchIncomingDate', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            // textDecoration: 'underline',
            fontWeight: 600,
          }}
          // onClick={() =>
          //   handleCellClick(
          //     info.row.original.batchNumber,
          //     'batch_incoming_date'
          //   )
          // }
        >
          {info.getValue()}
        </Button>
      ),
      header: () => (
        <span style={{ fontWeight: 600 }}>Batch Incoming Date</span>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('totalIndividualNumbers', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            // textDecoration: 'underline',
            fontWeight: 600,
          }}
          // onClick={() =>
            // handleCellClick(info.row.original.batchNumber, 'total_individual')
          // }
        >
          {info.getValue()}
        </Button>
      ),
      header: () => (
        <span style={{ fontWeight: 600 }}>Total Individual Numbers</span>
      ),
      footer: (info) => info.column.id,
    }),
    // columnHelper.accessor('batchOutput', {
    //   cell: (info) => (
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       sx={{ textTransform: 'none' }}
    //       disabled={downloadingBatch === info.row.original.batchNumber}
    //       onClick={() => handleDownload(info.row.original.batchNumber)}
    //     >
    //       {downloadingBatch === info.row.original.batchNumber ? (
    //         'Downloading...'
    //       ) : (
    //         <>
    //           Download <DownloadIcon sx={{ marginLeft: '4px' }} />
    //         </>
    //       )}
    //     </Button>
    //   ),
    //   header: () => <span style={{ fontWeight: 600 }}>Batch Output</span>,
    //   footer: (info) => info.column.id,
    // }),
  ];

  const startIndex = pagination.pageIndex * pagination.pageSize + 1; // Start index (1-based)
  const endIndex = Math.min(startIndex + pagination.pageSize - 1, total); // End index (1-based)
  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(total / pagination.pageSize), // Update page count based on total
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true, // We're doing manual "server-side" pagination
    debugTable: false,
  });

  return (
    <Box sx={{ padding: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h5" fontWeight={600} gutterBottom>
          ICAP Dashboard
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            placeholder="Enter Batch Number"
            variant="outlined"
            size="small"
            sx={{ width: '50%', mr: 1 }}
            onChange={(e) => setFilterValue(e.target.value)} // Update state on change
          />
          <Button variant="contained" color="primary" onClick={applyFilter}>
            Apply Filter
          </Button>
        </Box>
      </Box>
      {isLoading ? ( // Show loading indicator if loading
        <Typography variant="h6">Loading data...</Typography>
      ) : (
        <div
          style={{
            maxHeight: '600px', // Set a fixed height for the table container
            overflowY: 'auto', // Enable vertical scrolling
            border: '1px solid #ddd',
          }}
        >
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              // marginTop: '20px',
              // border: '1px solid #ddd',
            }}
          >
            <thead
              style={{
                position: 'sticky',
                top: 0,
                background: '#f5f5f5',
                zIndex: 3,
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        // border: '1px solid #ddd', // Add border to header cells
                        padding: '8px',
                        textAlign: 'center',
                        background: '#f5f5f5', // Optional background for headers
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        border: '1px solid #ddd', // Add border to body cells
                        padding: '8px',
                        textAlign: 'center',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end', // Align items to the right
          alignItems: 'center',
          width: '100%',
          marginTop: '16px',
        }}
      >
        <span style={{ marginRight: '16px' }}>
          Showing {startIndex} to {endIndex} of {total.toLocaleString()} Rows
        </span>

        <TextField
          select
          label="Per Page"
          value={pagination.pageSize}
          onChange={(e) => {
            setPagination((prev) => ({
              ...prev,
              pageSize: Number(e.target.value),
              pageIndex: 0, // Reset to first page when page size changes
            }));
          }}
          sx={{
            width: '100px',
            marginRight: '16px',
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center', // Center the text vertically
            },
            '& .MuiInputBase-root': {
              height: '30px', // Match the height of the pagination box
            },
          }}
          variant="outlined" // Use outlined variant for better styling
        >
          {[10, 25, 50, 100].map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </TextField>

        <Pagination
          count={table.getPageCount()} // Total number of pages
          page={table.getState().pagination.pageIndex + 1} // Current page (1-based index)
          onChange={(event, value) => {
            table.setPageIndex(value - 1); // Set the page index (0-based)
          }}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default App;
