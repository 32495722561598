import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Checkbox,
  Pagination,
  MenuItem,
  TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation for accessing passed state
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  PaginationState,
  createColumnHelper,
  Row,
} from '@tanstack/react-table'; // Import react-table hooks
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Define a type for your data
type FileData = {
  id: number;
  fileName: string;
  filePath: string;
  download?: string;
};

const apiUrl = process.env.REACT_APP_API_URL;

const ViewFiles = () => {
  const [total, setTotal] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [isLoading, setIsLoading] = useState<boolean>(false); // Add loading state

  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation(); // Get the current location
  const queryParams = new URLSearchParams(location.search); // Parse query parameters

  const batchNumber = queryParams.get('batchNumber'); // Access batchNumber
  const type = queryParams.get('type'); // Access columnType
  const subType = queryParams.get('sub_type'); // Access value1 if it exists

  // Define fetchDataOnLoad function before using it in useQuery
  const fetchDataOnLoad = async (pageIndex: number, pageSize: number) => {
    setIsLoading(true); // Set loading to true before fetching
    try {
      const currUrl = `${apiUrl}/dashboard/view_files/${batchNumber}`;

      const params: {
        page: number;
        limit: number;
        type: string | null;
        sub_type?: string | null;
      } = {
        page: pageIndex + 1,
        limit: pageSize,
        type: type,
      };

      if (subType !== '') {
        params.sub_type = subType;
      }

      const response = await axios.get(currUrl, { params });
      const { data, count } = response.data;
      setTotal(count);
      return data; // Return the fetched data
    } catch (error) {
      console.error('Error fetching data on load:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setRowSelection({});
  }, [pagination.pageIndex, pagination.pageSize]); // Ensure dependencies are correct

  // Keep only one declaration of dataQuery
  const dataQuery = useQuery({
    queryKey: ['data', pagination],
    queryFn: () => fetchDataOnLoad(pagination.pageIndex, pagination.pageSize),
    // placeholderData: keepPreviousData ,
  });

  const data = dataQuery.data || []; // Use fetched data or an empty array

  const columnHelper = createColumnHelper<FileData>();

  // Function to handle cell click
  const handleCellClick = (
    batchNumber: string,
    columnType: string,
    columnGroupValues?: { value1: number; value2: number }
  ) => {
    console.log('batchNumber :>> ', batchNumber);
  };

  const backToDashboard = () => {
    navigate('/');
  };

  const downloadSelectedFiles = async () => {
    const selectedRows = table.getState().rowSelection;
    const currentPageData = data.filter(
      (row: any, index: number) => selectedRows[index]
    );
    const fileKeys = currentPageData.map((row: any) => row.filePath);
    await handleDownload(fileKeys);
  };

  const handleDownload = async (fileKeys: string[]) => {
    try {
      if (fileKeys.length > 0) {
        const response = await axios.post(
          `${apiUrl}/dashboard/download-files`,
          {
            fileKeys: fileKeys,
          },
          { responseType: 'blob' }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'files.zip'); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('File path is undefined');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const columns = [
    {
      id: 'select-col',
      header: ({ table }: { table: any }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }: { row: Row<FileData> }) => (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
    },
    columnHelper.accessor('fileName', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            textDecoration: 'underline',
            fontWeight: 600,
          }}
          onClick={() => handleCellClick(info.getValue(), 'batchNumber')}
        >
          {info.getValue()}
        </Button>
      ),
      header: () => <span style={{ fontWeight: 600 }}>Document Name</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('download', {
      cell: (info) => (
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none' }}
          onClick={() => handleDownload([info.row.original.filePath])}
        >
          Download File <DownloadIcon sx={{ marginLeft: '4px' }} />
        </Button>
      ),
      header: () => <span style={{ fontWeight: 600 }}>Download File</span>,
      footer: (info) => info.column.id,
    }),
  ];

  const startIndex = pagination.pageIndex * pagination.pageSize + 1; // Start index (1-based)
  const endIndex = Math.min(startIndex + pagination.pageSize - 1, total); // End index (1-based)

  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(total / pagination.pageSize),
    state: {
      pagination,
      rowSelection, // Use the rowSelection state here
    },
    onPaginationChange: (updater) => {
      setPagination((prev) => {
        const newPagination =
          typeof updater === 'function' ? updater(prev) : updater;
        return {
          ...newPagination,
        };
      });
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection, // Update row selection state
  });

  // useEffect(() => {
  //   // Reset row selection when the page changes
  //   setRowSelection({});
  // }, [pagination.pageIndex]);

  function toPascalCase(input: string): string {
    return input
      .replace(/([-_ ]\w)/g, (match: string) => match.charAt(1).toUpperCase())
      .replace(/^[a-z]/, (match: string) => match.toUpperCase())
      .replace(/[-_ ]/g, '');
  }

  const pageTitle = () => {
    const formattedType = toPascalCase(type || '');
    const formattedSubType = subType ? toPascalCase(subType) : '';
    return `${formattedType} ${formattedSubType} Files`.trim();
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={600}>
            {pageTitle()}
          </Typography>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Total Files: {total}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            sx={{ mr: 2 }}
            onClick={backToDashboard}
          >
            Back to Dashboard
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadSelectedFiles}
          >
            Download Selected Files
          </Button>
        </Box>
      </Box>
      {isLoading ? ( // Show loading indicator if loading
        <Typography variant="h6">Loading data...</Typography>
      ) : (
        <div
          style={{
            maxHeight: '600px', // Set a fixed height for the table container
            overflowY: 'auto', // Enable vertical scrolling
            border: '1px solid #ddd',
          }}
        >
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              // marginTop: '20px',
              // border: '1px solid #ddd',
            }}
          >
            <thead
              style={{
                position: 'sticky',
                top: 0,
                background: '#f5f5f5',
                zIndex: 3,
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        padding: '8px',
                        textAlign: 'center',
                        background: '#f5f5f5', // Optional background for headers
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        border: '1px solid #ddd', // Add border to body cells
                        padding: '8px',
                        textAlign: 'center',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end', // Align items to the right
          alignItems: 'center',
          width: '100%',
          marginTop: '16px',
        }}
      >
        <span style={{ marginRight: '16px' }}>
          Showing {startIndex} to {endIndex} of {total.toLocaleString()} Rows
        </span>

        <TextField
          select
          label="Per Page"
          value={pagination.pageSize}
          onChange={(e) => {
            setPagination((prev) => ({
              ...prev,
              pageSize: Number(e.target.value),
              pageIndex: 0, // Reset to first page when page size changes
            }));
          }}
          sx={{
            width: '100px',
            marginRight: '16px',
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center', // Center the text vertically
            },
            '& .MuiInputBase-root': {
              height: '30px', // Match the height of the pagination box
            },
          }}
          variant="outlined" // Use outlined variant for better styling
        >
          {[10, 25, 50, 100].map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </TextField>

        <Pagination
          count={table.getPageCount()} // Total number of pages
          page={table.getState().pagination.pageIndex + 1} // Current page (1-based index)
          onChange={(event, value) => {
            table.setPageIndex(value - 1); // Set the page index (0-based)
          }}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default ViewFiles;
